<template>
	<v-card outlined :color="`blue lighten-5`" class="h-100 d-flex flex-column overflow-hidden">
		<v-card-title>
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<v-icon class="mr-2">mdi-chart-areaspline</v-icon>
			<div class="my-auto fw-600 text-uppercase fs-18">Lead Overview</div>
			<v-spacer></v-spacer>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn height="35" min-height="35" color="cyan lighten-4" depressed v-bind="attrs" v-on="on">
						<span class="fs-16 text-capitalize px-1">{{ dActiveLeadSalesDuration.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in filterDurationList"
						:key="index"
						@click="filterByDuration(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': dActiveLeadSalesDuration.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>
		<v-card-text class="flex-grow-1 position-relative">
			<v-img class="sale-symbol" :src="$assetURL('media/bg/lead-source-3.png')"></v-img>
			<div class="d-flex h-100">
				<div v-if="false" class="w-100 d-flex flex-wrap align-center justify-center">
					<template v-if="countLoading">
						<div style="height: 226px; width: 300px" class="d-flex align-center justify-center">
							<v-progress-circular indeterminate size="60" width="6" color="cyan"></v-progress-circular>
						</div>
					</template>
					<template v-else>
						<apexchart
							:key="JSON.stringify(getColors)"
							type="pie"
							:options="chartOptions"
							:series="getSeries"
							width="300"
						></apexchart>
					</template>
					<div class="flex-grow-1" style="max-width: 200px">
						<div class="border-bottom-dotted py-1 px-1" v-for="(row, index) in graphs" :key="index">
							<div class="fw-500 fs-16 d-flex align-center text-right">
								<v-spacer></v-spacer>
								<span :class="`${row.color}--texttt text-truncate`">
									{{ row.name }}
								</span>
								<div style="min-width: 50px">
									<v-chip
										style="min-width: 32px; min-height: 32px"
										medium
										class="pa-1 d-inline-flex justify-center"
										:color="row.color"
									>
										<template v-if="countLoading">
											<v-progress-circular
												indeterminate
												size="14"
												width="2"
												color="white"
											></v-progress-circular>
										</template>
										<template v-else>
											<span class="fs-18 white--text">
												{{ row.total }}
											</span>
										</template>
									</v-chip>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-else
					class="w-100 v-data-table bt-table theme--light position-relative rounded-lg overflow-hidden"
					style="background: transparent"
				>
					<div class="v-data-table__wrapper" style="max-height: 300px; height: auto">
						<table id="table" width="100">
							<thead>
								<tr>
									<th class="pa-2">Sales Person</th>
									<th class="pa-2 text-center" width="130">Email Marketer</th>
									<th class="pa-2 text-center" width="110">Tele Call</th>
									<th class="pa-2 text-center" width="100">Others</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(user, index) in dListingLeadSales" :key="index">
									<td class="pa-2">
										<div class="d-flex align-center">
											<v-avatar
												size="40"
												class="border"
												style="border: 1px solid rgb(155, 158, 155) !important"
											>
												<v-img :src="$assetURL('media/users/blank.png')" contain />
											</v-avatar>
											<div class="pl-2">
												<div class="fw-600">{{ user.display_name }}</div>
												<small v-if="user.email">{{ user.email }}</small>
											</div>
										</div>
									</td>
									<td class="pa-2 text-center">
										<v-chip
											style="min-width: 32px; min-height: 32px"
											medium
											outlined
											class="pa-1 d-inline-flex justify-center"
											color="orange"
											dark
										>
											<template v-if="countLoading">
												<v-progress-circular
													indeterminate
													size="14"
													width="2"
													color="white"
												></v-progress-circular>
											</template>
											<template v-else>
												<span class="fs-18"> {{ user.email_marketer }} </span>
											</template>
										</v-chip>
									</td>
									<td class="pa-2 text-center">
										<v-chip
											style="min-width: 32px; min-height: 32px"
											medium
											outlined
											dark
											class="pa-1 d-inline-flex justify-center"
											color="cyan"
										>
											<template v-if="countLoading">
												<v-progress-circular
													indeterminate
													size="14"
													width="2"
													color="white"
												></v-progress-circular>
											</template>
											<template v-else>
												<span class="fs-18"> {{ user.tele_call }} </span>
											</template>
										</v-chip>
									</td>
									<td class="pa-2 text-center">
										<v-chip
											style="min-width: 32px; min-height: 32px"
											medium
											outlined
											dark
											class="pa-1 d-inline-flex justify-center"
											color="brown"
										>
											<template v-if="countLoading">
												<v-progress-circular
													indeterminate
													size="14"
													width="2"
													color="white"
												></v-progress-circular>
											</template>
											<template v-else>
												<span class="fs-18"> {{ user.other }} </span>
											</template>
										</v-chip>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
import { cloneDeep } from "lodash";
import {
	GET_LISTING_LEAD_SALES,
	SET_ACTIVE_FILTER,
} from "@/core/services/store/dashboard.module.js";
import { mapGetters } from "vuex";
export default {
	name: "LeadOverviewStatus",
	data() {
		return {
			filter: "This Month",
			countLoading: false,

			filterDurationList: [
				{ text: "Today", value: "today" },
				{ text: "This Week", value: "this_week" },
				{ text: "This Month", value: "this_month" },
				{ text: "Last Month", value: "last_month" },
				{ text: "This Quarter", value: "this_quarter" },
				{ text: "This Year", value: "this_year" },
			],
			filterStatusList: [
				"Today",
				"This Week",
				"This Month",
				"Last Month",
				"This Quarter",
				"This Year",
			],
			series: [
				{
					name: "series-a",
					data: [13, 12, 33, 53, 65],
					color: "#EE6D7A",
				},
			],
			graphs: [
				{
					color: "orange",
					name: "Email Marketer",
					total: 20,
				},
				{
					color: "cyan",
					name: "Tele Call",
					total: 30,
				},
				{
					color: "brown",
					name: "Others",
					total: 44,
				},
			],
			chartOptions: {
				chart: {
					type: "pie",
					width: 300,
				},
				legend: {
					position: "bottom",
					show: false,
				},
				colors: this.getColors,
			},
		};
	},
	methods: {
		hexColor(color) {
			switch (color) {
				case "red":
					return "#F44336";
				case "orange":
					return "#FF9800";
				case "cyan":
					return "#00BCD4";
				case "brown":
					return "#795548";
				case "light-blue":
					return "#03A9F4";
				case "teal":
					return "#009688";
				case "green":
					return "#4CAF50";
			}
		},
		filterData(item) {
			if (this.filter != item) {
				this.filter = item;
				this.countLoading = true;
				setTimeout(() => {
					this.countLoading = false;
				}, 2000);
			}
		},
		filterByDuration(item) {
			if (item.value != this.dActiveLeadSalesDuration.value) {
				this.$store.commit(SET_ACTIVE_FILTER, { key: "active_lead_sales_duration", value: item });
				this.getLeads();
			}
		},
		getLeads() {
			this.countLoading = true;
			this.$store
				.dispatch(GET_LISTING_LEAD_SALES, {
					filter: this.dActiveLeadSalesDuration.value,
				})
				.finally(() => {
					this.countLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["dActiveLeadSalesDuration", "dListingLeadSales"]),
		getSeries() {
			return this.graphs.map((row) => {
				return row.total;
			});
		},
		getLabels() {
			return this.graphs.map((row) => {
				return row.name;
			});
		},
		getColors() {
			return this.graphs.map((row) => this.hexColor(row.color));
		},
	},
	mounted() {
		const options = cloneDeep(this.chartOptions);
		this.chartOptions = { ...options, colors: this.getColors, labels: this.getLabels };
	},
};
</script>
<style lang="scss" scoped>
.v-data-table__wrapper {
	tr:nth-child(even):not(.no-hover) {
		background: #fbf5eb;
	}
	tr {
		td {
			padding: 12px 12px !important;
		}
	}
}
.sale-symbol {
	height: 250px;
	width: 250px;
	position: absolute;
	bottom: -30px;
	left: -30px;
	opacity: 0.1;
}
.sale2-symbol {
	height: 350px;
	width: 350px;
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0.05;
	transform: translate(-50%, -50%);
}
</style>
